import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewContainerRef,
  inject,
} from "@angular/core";

import {
  DialogBoxTransmitterService,
  DialogPayload,
} from "@theme/@confect/services/confect-dialog-box-transmitter.service";
import {
  DialogBoxService,
  DialogRef,
} from "@theme/@confect/services/confect-dialog-box.service";

import { Subject, fromEvent, takeUntil, tap } from "rxjs";

@Directive({
  selector: "[dialogBoxTarget]",
})
export class DialogBoxTargetDirective
  implements OnInit, OnDestroy, AfterViewInit
{
  private readonly document = inject(DOCUMENT);

  unsubscribe$ = new Subject<any>();
  destroy$$ = new Subject<any>();

  @Input({ required: true }) targetID;
  @Input({ required: true }) dialogPosition:
    | "above"
    | "below"
    | "left"
    | "right";
  @Input() dialogAlign: "top" | "bottom" | "right" | "left" | "center" =
    "center";
  @Input() dialogDisable: boolean = false;

  dialogRef: DialogRef | null;

  constructor(
    private elementRef: ElementRef,
    private dialogService: DialogBoxService,
    private dialogTransmitter: DialogBoxTransmitterService,
    private viewRef: ViewContainerRef,
  ) {}

  ngOnInit(): void {
    this.dialogTransmitter.dialogObservable
      .pipe(takeUntil(this.destroy$$))
      .subscribe({
        next: (res) => {
          if (res.id !== this.targetID) {
            if (this.dialogRef != null) {
              this.dialogRef.close();
            }
            this.unsubscribe$.next(true);
            return;
          }

          this.open(res);
        },
      });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (this.dialogRef != null) {
      this.dialogRef.close();
    }
    this.unsubscribe$.next(true);
    this.destroy$$.next(true);
  }

  open(payload: DialogPayload) {
    if (this.dialogRef != null) {
      this.dialogRef?.close();
      this.unsubscribe$.next(true);
      return;
    }
    if (this.dialogDisable || payload.template == null) {
      return;
    }

    payload.event?.stopPropagation();
    const boundingRect = this.elementRef.nativeElement.getBoundingClientRect();
    this.dialogRef = this.dialogService.open({
      template: payload.template,
      position: this.dialogPosition,
      align: this.dialogAlign,
      parentPosition: boundingRect,
      event: payload.event,
      highlight: true,
    });

    this.dialogRef?.afterClose.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: () => {
        this.dialogRef = null;
        if (payload.postEvent) {
          payload.postEvent();
        }
      },
    });

    const selfClick$ = fromEvent(
      this.dialogRef?.elmRef.nativeElement,
      "click",
    ).pipe(
      takeUntil(this.unsubscribe$),
      tap((event: MouseEvent) => {
        event.preventDefault();
        event.stopPropagation();
      }),
    );
    selfClick$.subscribe();

    const click$ = fromEvent(document, "click").pipe(
      takeUntil(this.unsubscribe$), // clear subscription
      tap((event: MouseEvent) => {
        if (this.dialogRef != null) {
          this.dialogRef.close();
          this.unsubscribe$.next(true);
        }
      }),
    );
    click$.subscribe();
  }
}
