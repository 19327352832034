import {
  AfterViewInit,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  Signal,
  TemplateRef,
  ViewChild,
  WritableSignal,
  computed,
  signal,
} from "@angular/core";

import { contextMenuOption } from "../../components/context-menu/context-menu.component";
import { TabContentDirective, TabDesignDirective } from "./tab-menu.directive";

export interface TabInfo {
  icon: string;
  name: string;
  id: string;
  disable?: boolean;
}

@Component({
  selector: "confect-tab-menu",
  templateUrl: "./tab-menu.component.html",
  styleUrl: "./tab-menu.component.scss",
})
export class CTabMenuComponent implements AfterViewInit, OnDestroy {
  _tabs: TabInfo[];
  @Input() set tabs(to: TabInfo[]) {
    this._tabs = to;
    this.resetTabs();
    this.updateContextOptions();
  }
  @Input() padding = true;
  @Output() tabChange = new EventEmitter<string>();

  @ViewChild("header") header: ElementRef;

  @ContentChild(TabDesignDirective, { read: TemplateRef })
  tabTemplate: TemplateRef<any>;
  @ContentChild(TabContentDirective, { read: TemplateRef })
  contentTemplate: TemplateRef<any>;

  tabCount: number = 1;
  currentTab: WritableSignal<string> = signal("");
  lastTab: Signal<TabInfo> = computed(() => {
    const inMore = this._tabs
      .map((tab) => tab.id === this.currentTab())
      .filter((_, i) => i >= this.tabCount - 2)
      .some((bool) => bool); //checks if the currentTab is shown or under more

    if (!inMore) {
      return this._tabs[this.tabCount];
    }
    return this._tabs.find((tab) => tab.id === this.currentTab());
  });

  contextOptions: contextMenuOption[];

  resizeObserver = new ResizeObserver(() => {
    this.resetTabs();
    this.updateContextOptions();
  });

  constructor() {}

  ngAfterViewInit(): void {
    this.resetTabs();
    this.updateContextOptions();
    this.resizeObserver.observe(this.header.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.unobserve(this.header.nativeElement);
  }

  resetTabs() {
    if (this.header == null) {
      return;
    }
    const headerWidth = this.header.nativeElement.offsetWidth;
    const tabWidth = this.header.nativeElement.children[0]?.offsetWidth;
    const tabs: TabInfo[] = JSON.parse(JSON.stringify(this._tabs));

    const tabCount = Math.round(headerWidth / (tabWidth + 9));
    this.tabCount = tabCount;
    this.currentTab.update(() => tabs[0].id);
  }

  updateContextOptions() {
    const tabs: TabInfo[] = JSON.parse(JSON.stringify(this._tabs));
    this.contextOptions = tabs
      .splice(this.tabCount - 2, tabs.length - this.tabCount + 2)
      .map((tab) => {
        return {
          display: tab.name,
          icon: tab.icon,
          function: () => this.setCurrentTab(tab.id),
          hide: () =>
            this.currentTab() === tab.id || this.lastTab().id === tab.id,
        };
      });
  }

  setCurrentTab = (tab) => {
    this.tabChange.emit(tab);
    this.currentTab.update(() => tab);
    this.updateContextOptions();
  };
}
