import { Injectable, TemplateRef } from "@angular/core";

import { Observable, Subject } from "rxjs";

export interface DialogPayload {
  template: TemplateRef<any>;
  id: string;
  event?: MouseEvent;
  postEvent?: () => void;
}

@Injectable({ providedIn: "root" })
export class DialogBoxTransmitterService {
  dialogSubject: Subject<DialogPayload> = new Subject<DialogPayload>();

  public get dialogObservable(): Observable<DialogPayload> {
    return this.dialogSubject.asObservable();
  }

  public transmit(payload: DialogPayload) {
    this.dialogSubject.next(payload);
  }
}
