<div
  #box
  class="fixed z-[2000]"
  [ngClass]="{
    'opacity-100 ': show,
    'opacity-0 ': !show,
    'pl-[40px]': _position() === 'right' && highlight,
    'pr-[40px]': _position() === 'left' && highlight,
    'pt-[40px]': _position() === 'below' && highlight,
    'pb-[40px]': _position() === 'above' && highlight,
    'pl-[20px]': _position() === 'right' && !highlight,
    'pr-[20px]': _position() === 'left' && !highlight,
    'pt-[20px]': _position() === 'below' && !highlight,
    'pb-[20px]': _position() === 'above' && !highlight,
    invisible: dialogBox() == null,
  }"
  [style.top.px]="top()"
  [style.left.px]="left()"
>
  <div
    class="rounded-md px-3 py-2 max-w-[500px] min-w-52 text-wrap"
    [ngClass]="{
      'bg-white ring-2 ring-gray-200': !highlight,
      'bg-gray-700 text-white': highlight,
    }"
  >
    <div class="-mt-2 flex items-center justify-end">
      <confect-icon
        class="cursor-pointer text-gray-400"
        [ngClass]="{ 'text-gray-400': !highlight, 'text-white': highlight }"
        icon="remove"
        (click)="$event.stopPropagation(); close()"
      />
    </div>
    <ng-template
      [ngTemplateOutlet]="template"
      [ngTemplateOutletContext]="{ close: close }"
    />
  </div>
</div>

@if (highlight) {
  <div
    class="fixed z-[2000]"
    [style.top.px]="highlightTop()"
    [style.left.px]="highlightLeft()"
    [ngClass]="{
      'w-[40px] h-0.5': _position() === 'right' || _position() === 'left',
      'h-[40px] w-0.5': _position() === 'below' || _position() === 'above',
    }"
  >
    <div
      class="z-[2000] w-6 h-6 rounded-full absolute"
      [ngClass]="{
        '-left-3 bottom-1/2 translate-y-3': _position() === 'right',
        '-right-3 top-1/2 -translate-y-3': _position() === 'left',
        '-top-3 right-1/2 translate-x-3': _position() === 'below',
        '-bottom-3 left-1/2 -translate-x-3': _position() === 'above',
      }"
    >
      <div
        class="ping inline-flex bg-secondary w-full h-full rounded-full absolute"
      ></div>
      <div class="pulse bg-secondary w-full h-full rounded-full absolute"></div>
      <div
        class="absolute w-3 h-3 bg-gray-700 rounded-full top-1/2 left-1/2 -translate-x-1.5 -translate-y-1.5"
      ></div>
    </div>
  </div>
  <div
    class="fixed z-[2000] bg-gray-700"
    [ngClass]="{
      'w-[40px] h-0.5': _position() === 'right' || _position() === 'left',
      'h-[40px] w-0.5': _position() === 'below' || _position() === 'above',
    }"
    [style.top.px]="highlightTop()"
    [style.left.px]="highlightLeft()"
  ></div>
}
