import { AfterContentInit, Component, Input, OnInit } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";

import { filter } from "rxjs/operators";

export interface CSideMenuItem {
  title: string;
  icon?: string;
  children?: CSideMenuItem[];
  route?: string[];
  action?: string;
  expanded?: boolean;
  selected?: boolean;
  enabled?: boolean;
  tooltip?: string;
  fire?: boolean;
  label?: string;
}

@Component({
  selector: "confect-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class CSideMenuComponent implements OnInit, AfterContentInit {
  expanded = false;

  _items: CSideMenuItem[] = [];

  @Input() homeRoute = ["/"];

  @Input() set items(to: CSideMenuItem[]) {
    this._items = to.map((item) => {
      return {
        ...item,
        expanded: item.expanded ?? false,
        selected: item.selected ?? false,
      };
    });
  }

  constructor(private router: Router) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart))
      .subscribe((event) => {
        const url = (event as NavigationStart).url;
        this.determineSelection(url);
      });
  }

  ngOnInit(): void {
    // console.log(this.route.snapshot.url.toString());
    // this.determineSelection(this.route.snapshot.url.toString());
  }

  ngAfterContentInit() {
    this.determineSelection(this.router.url);
  }

  determineSelection(url: string) {
    const urlString = url.replace(/\//g, "");

    this._items.forEach((item) => {
      item.selected = item.route?.join("").replace(/\//g, "") === urlString;
      if (item.children) {
        item.children.forEach((child) => {
          child.selected =
            child.route?.join("").replace(/\//g, "") === urlString;
        });

        item.expanded = item.children.some((child) => child.selected === true);
      }
    });
  }

  itemSelected(item: CSideMenuItem) {
    if (item.enabled === false) {
      return;
    }

    if (item.route) {
      this.router.navigate(item.route);
    }
  }
}
