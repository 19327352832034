import { IntegrationIssue } from "./integration.models";
import { AccountState } from "./settings.models";

export interface AccessTrialInfo {
  started: boolean;
  ended: boolean;
  on_trial: boolean;
  end_date: string;
  days_left: number;
}

export interface AccountFeatureMeta {
  name: string;
  key: string;
  icon?: string;
  cancalled_at: string;
  trial_end_date: string;
  trial_info: AccessTrialInfo;
}

export enum AccountImageMode {
  REMOVE_BG = "REMOVE_BG",
  MIX = "MIX",
  DO_NOT_REMOVE_BG = "DO_NOT_REMOVE_BG",
}

export interface AccountAccessInfo {
  id: number;
  name: string;
  active: boolean;
  onboarding_done: boolean;
  has_feed: boolean;
  trial_info: AccessTrialInfo;
  permissions: Record<string, boolean>;
  features: Record<string, AccountFeatureMeta>;
  image_mode: AccountImageMode;
  integration_issues: Record<string, Record<string, IntegrationIssue> | null>;

  state: AccountState;
  spend: number | null;
}

export interface CompanyAccessInfo {
  id: number;
  name: string;
  active: boolean;
  trial_info: AccessTrialInfo;
  accounts: AccountAccessInfo[];
  permissions: Record<string, boolean>;
  has_payment_method: boolean;
  info: Record<string, any>;
  subscription_v2: boolean;
}

export interface MeAgency {
  id: number;
  name: string;
  info: Record<string, any>;
  has_facebook: boolean;
}

export interface Me {
  id: number;
  name: string;
  email: string;

  permissions: Record<string, boolean>;
  agency?: MeAgency;
  anon: boolean;
  info: Record<string, any>;
}

export interface DealOwnerProfile {
  user_id: number;
  full_name: string;
  first_name: string;
  last_name: string;
  image_url: string;
  meeting_link: string;
  email: string;
}
