import {
  Component,
  ElementRef,
  Input,
  Output,
  Signal,
  TemplateRef,
  ViewChild,
  WritableSignal,
  computed,
  signal,
  EventEmitter,
  AfterViewInit,
} from "@angular/core";

@Component({
  selector: "confect-dialog-box",
  templateUrl: "./dialog-box.component.html",
  styleUrls: ["./dialog-box.component.scss"],
})
export class DialogBoxComponent implements AfterViewInit {
  @Input() template: TemplateRef<any>;
  @Input() highlight: boolean = false;

  _parentPosition: WritableSignal<any> = signal(null);
  @Input({ required: true }) set parentPosition(to: any) {
    if (!to) {
      return;
    }
    this._parentPosition.update(() => to);
  }

  _position: WritableSignal<"above" | "below" | "left" | "right" | null> =
    signal(null);
  @Input({ required: true }) set position(
    to: "above" | "below" | "left" | "right",
  ) {
    if (!to) {
      return;
    }
    this._position.update(() => to);
  }
  @Input() align;

  dialogBox: WritableSignal<any | null> = signal(null);

  @ViewChild("box") boxElement: ElementRef<any>;

  highlightTop: Signal<number | null> = computed(() => {
    if (!this.dialogBox()) {
      return null;
    }

    const parentCenter =
      (this._parentPosition().bottom + this._parentPosition().top) / 2;

    switch (this._position()) {
      case "above":
        return this._parentPosition().top - 40;
      case "below":
        return this._parentPosition().bottom;
      default:
        return parentCenter;
    }
  });

  highlightLeft: Signal<number | null> = computed(() => {
    if (!this.dialogBox()) {
      return null;
    }

    const parentCenter =
      (this._parentPosition().left + this._parentPosition().right) / 2;

    switch (this._position()) {
      case "left":
        return this._parentPosition().left - 40;
      case "right":
        return this._parentPosition().right;
      default:
        return parentCenter;
    }
  });

  top: Signal<number | null> = computed(() => {
    if (!this.dialogBox()) {
      return null;
    }

    let offsetBottom = 0;
    let offsetTop = 0;

    const parentCenter =
      (this._parentPosition().bottom + this._parentPosition().top) / 2;
    const tooltipCenter = (this.dialogBox().bottom - this.dialogBox().top) / 2;

    switch (this._position()) {
      case "above":
        return this._parentPosition().top - this.dialogBox().height;
      case "below":
        return this._parentPosition().bottom;
      default:
        let pos = 0;
        switch (this.align) {
          case "top":
            pos = this._parentPosition().top;
            break;
          case "bottom":
            pos = this._parentPosition().bottom - this.dialogBox().height;
            break;
          default:
            pos = parentCenter - tooltipCenter;
            break;
        }

        offsetBottom = Math.min(
          window.innerHeight - (pos + this.dialogBox().height) - 10,
          0,
        );
        offsetTop = Math.max(0, -pos + 10);

        return pos + offsetBottom + offsetTop;
    }
  });

  left: Signal<number | null> = computed(() => {
    if (!this.dialogBox()) {
      return null;
    }

    let offsetRight = 0;
    let offsetLeft = 0;

    const parentCenter =
      (this._parentPosition().left + this._parentPosition().right) / 2;

    const tooltipCenter = (this.dialogBox().right - this.dialogBox().left) / 2;

    switch (this._position()) {
      case "left":
        return this._parentPosition().left - this.dialogBox().width;
      case "right":
        return this._parentPosition().right;
      default:
        let pos = 0;
        switch (this.align) {
          case "left":
            pos = this._parentPosition().left;
            break;
          case "right":
            pos = this._parentPosition().right - this.dialogBox().width;
            break;
          default:
            pos = parentCenter - tooltipCenter;
            break;
        }

        offsetRight = Math.min(
          window.innerWidth - (pos + this.dialogBox().width) - 10,
          0,
        );
        offsetLeft = Math.max(0, -pos + 10);

        return pos + offsetRight + offsetLeft;
    }
  });

  constructor(public elmRef: ElementRef) {}

  show: boolean = false;

  @Output() afterClose = new EventEmitter<any>();
  @Output() hovered = new EventEmitter<any>();

  ngAfterViewInit(): void {
    this.dialogBox.update(() =>
      this.boxElement?.nativeElement.getBoundingClientRect(),
    );
    this.show = true;
  }

  switch = () => {
    this.show = !this.show;
  };

  close = () => {
    this.afterClose.emit();
  };
}
