@if (show) {
  <div class="flex flex-col h-6 w-6 items-center justify-center animate-spin">
    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask id="myMask">
          <!-- Everything under a white pixel will be visible -->
          <circle cx="12" cy="12" r="12" fill="white" />

          <!-- Everything under a black pixel will be invisible -->
          <circle cx="12" cy="12" r="8" fill="black" />
        </mask>
      </defs>

      <rect
        x="0"
        y="0"
        width="24"
        height="24"
        style="fill: rgb(215 187 247)"
        mask="url(#myMask)"
      />
      <rect
        x="0"
        y="0"
        width="12"
        height="12"
        style="fill: rgb(152 82 219)"
        mask="url(#myMask)"
      />
    </svg>
  </div>
}
