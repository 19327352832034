<div
  style="visibility: hidden; position: fixed"
  [style.left]="menuTopLeftPosition.x"
  [style.top]="menuTopLeftPosition.y"
  [matMenuTriggerFor]="rightMenu"
></div>

<mat-menu
  #rightMenu="matMenu"
  class="font-normal"
  [hasBackdrop]="false"
  [autofocus]="false"
>
  <ng-template matMenuContent let-item="item">
    @for (option of _options; track option) {
      @if (!option.hide(item)) {
        <button
          mat-menu-item
          (click)="option.function(item)"
          class="font-sans focus:bg-white"
          [disabled]="option.disable(item)"
          tooltip
          [tooltipText]="option.disableMsg"
          tooltipPosition="left"
          [tooltipDisable]="!option.disable(item) || option.disableMsg == null"
        >
          <confect-icon
            [icon]="option.icon"
            iconClass="mr-2 text-lg text-gray-500"
          /><span>{{ option.display }}</span>
        </button>
      }
    }
  </ng-template>
</mat-menu>
