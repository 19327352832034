<div
  class="mx-auto"
  [ngClass]="{
    'grid grid-cols-3 mt-4 gap-y-6 gap-x-8': !onboarding,
    'flex flex-col xl:gap-y-4 gap-y-2 3xl:mx-40': onboarding,
  }"
>
  @for (option of options; track option) {
    @if (!onboarding) {
      <div
        class="relative bg-white rounded-lg shadow-sm p-4"
        [ngClass]="{
          'border-2 border-primary': option.value === _value,
          border: option.value !== _value,
        }"
      >
        <span class="flex flex-col items-center w-full space-y-4">
          <span class="block text-lg font-medium text-gray-900">
            {{ option.name }}
          </span>
          <div
            [lazyLoad]="option.image"
            class="block w-full bg-contain bg-no-repeat bg-center h-16"
          ></div>
          <span class="mt-1 flex items-center text-sm text-gray-500">
            {{ option.description }}
          </span>
          <confect-button
            type="secondary"
            (click)="settingsChange(option.value)"
            >Select</confect-button
          >
        </span>
      </div>
    } @else {
      <div
        class="relative bg-white rounded-lg shadow-sm p-2 h-28 flex justify-between border-2"
        [ngClass]="{
          'border-primary': option.value === _value,
          'border-gray-200': option.value !== _value,
        }"
      >
        <span class="flex flex-col w-full space-y-2">
          <span class="block text-xl font-medium text-gray-900">
            {{ option.name }}
          </span>
          <div class="flex w-full">
            <span class="flex text-base text-gray-600">
              {{ option.description }}
            </span>
          </div>
        </span>
        <div class="flex items-end">
          <confect-button
            type="secondary"
            (click)="settingsChange(option.value)"
            >Select</confect-button
          >
        </div>
      </div>
    }
  }
</div>
