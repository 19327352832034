import { Component, Input } from "@angular/core";

import { DealOwnerProfile } from "@core/api-models/users.models";

@Component({
  selector: "ngx-deal-owner",
  templateUrl: "./deal-owner.component.html",
})
export class DealOwnerComponent {
  @Input({ required: true }) dealOwner: DealOwnerProfile;
}
