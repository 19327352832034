import { ElementRef, EventEmitter, Injectable } from "@angular/core";

import { Observable, Subject } from "rxjs";

export interface DialogRef {
  close: () => void;
  switch: () => void;
  options: any;
  elmRef: ElementRef;
  afterClose: EventEmitter<void>;
}

@Injectable({ providedIn: "root" })
export class DialogBoxService {
  /**
   * All the necessary data about the modal which should be opened is being
   * sent to the outlet-modal component through this subject.
   */
  private dialogSubject: Subject<any> = new Subject<any>();

  public get dialogObservable(): Observable<any> {
    return this.dialogSubject.asObservable();
  }

  open<C>(opts) {
    const dialogRef: DialogRef = {
      options: opts,
    } as DialogRef;

    this.dialogSubject.next({
      ...opts,
      dialogRef,
    });

    return dialogRef;
  }
}
