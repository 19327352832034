import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import {
  CDropdownComponent,
  CDropdownItem,
} from "../dropdown/dropdown.component";

@Component({
  selector: "confect-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class CButtonComponent implements OnInit, OnDestroy {
  @Input() type: "primary" | "secondary" | "white" | "danger" | "subtle" =
    "primary";
  @Input() size:
    | "tiny"
    | "xsmall"
    | "small"
    | "medium"
    | "large"
    | "xlarge"
    | "xxlarge" = "medium";
  @Input() disabled = false;

  @Input() active = false;

  @Input() block = false;

  @Input() submit = false;

  @Output() dropdownItemSelected = new EventEmitter<CDropdownItem>();

  showMenu = false;

  @Input() dropdownItems: CDropdownItem[][] | null = null;

  @ViewChild("anchor", { static: true }) anchor!: ElementRef;
  @ViewChild("dropdown") dropdown: CDropdownComponent;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.showMenu = false;
    this.determineListen();
  }

  determineListen() {
    if (this.showMenu) {
      window.addEventListener("mousedown", this.mouseDown, true);
    } else {
      window.removeEventListener("mousedown", this.mouseDown);
    }
  }

  buttonClick(event: MouseEvent) {
    if (this.disabled) {
      return;
    }

    if (this.dropdownItems) {
      this.menuToggle(event);
    }
  }

  menuToggle(_event: MouseEvent) {
    this.dropdown?.calculatePosition();
    this.showMenu = !this.showMenu;
    this.determineListen();
  }

  itemSelected(selection: CDropdownItem) {
    this.showMenu = false;
    this.dropdownItemSelected.emit(selection);
    this.determineListen();
  }

  mouseDown = (event: MouseEvent) => {
    const target = event.target as any;
    const targetParent = target.parentElement;

    const role = target["attributes"]["role"];
    const isMenuItem = role?.value === "menuitem";

    const targetIsMenu = target?.id === "option-menu";
    const parentIsMenu = targetParent?.id === "option-menu";

    if (!targetIsMenu && !parentIsMenu && !isMenuItem) {
      this.showMenu = false;
    }
    this.determineListen();
  };
}
